import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

import { ToasterNotification } from '@monorepo/components/DataDisplay';
import { Form, SocialMediaButton, StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import { useAnalytics } from '@monorepo/shared/lib/hooks';
import { emailSchema, EmailSchema } from '@monorepo/shared/lib/schemas';
import { isDevelopment } from '@monorepo/shared/lib/utils';
import { supportedSocialLogins, SupportedSocialLogins } from '@monorepo/types';

import { useLogin } from '@auth/hooks';
import { useMagicLinkMutation } from '@auth/store';
import { selectTheme } from '@common/store';
import { paths } from '@common/utils';

type Props = {
	variant?: 'default' | 'funnel'; // The funnel variant differs slightly in UI
	continueUrl?: `/${string}`;
};

export const LoginForm: FC<Props> = ({ variant = 'default', continueUrl = paths.portal.dashboard.root }) => {
	const { t } = useTranslation(['common']);
	const { loginUserWithGoogle, loginUserWithMicrosoft, setLoginEmailForMagicLink } = useLogin();
	const [magicLink, magicLinkResponse] = useMagicLinkMutation();
	const [isFirstTimeLogin] = useLocalStorage<string>('is_first_time_login');
	const theme = useSelector(selectTheme);
	const { push } = useRouter();
	const { trackEvent } = useAnalytics();

	const {
		register,
		formState: { isValid, errors },
		handleSubmit,
		reset,
	} = useForm<EmailSchema>({
		mode: 'onBlur',
		resolver: zodResolver(emailSchema(t)),
	});

	const onSubmit = async ({ email }: EmailSchema) => {
		await magicLink({
			email,
			salesChannel: theme,
			redirectUrl: `${window.location.origin}${continueUrl}`,
		})
			.unwrap()
			.then(async ({ loginLink }) => {
				setLoginEmailForMagicLink(email);

				if (isDevelopment && loginLink) {
					await push(loginLink);
				}

				toast.custom(
					({ visible }) => (
						<ToasterNotification
							title={t('page.auth.login.toastSuccessTitle')}
							visible={visible}
							message={t('page.auth.login.toastSuccessMessage')}
							status='success'
						/>
					),
					{
						ariaProps: {
							role: 'alert',
							'aria-live': 'assertive',
						},
					}
				);
			});
	};

	const onSocialLoginClick = (socialLogin: SupportedSocialLogins) => {
		const login = socialLogin === 'google' ? loginUserWithGoogle : loginUserWithMicrosoft;

		login().then(async () => {
			trackEvent('login', { method: socialLogin });
			if (isFirstTimeLogin !== 'false') {
				const queryParams: Record<string, string> = {};

				queryParams.continueUrl = encodeURIComponent(continueUrl);

				await push({
					pathname: paths.portal.onboarding.root,
					query: queryParams,
				});
			} else {
				await push(continueUrl);
			}
		});
	};

	// Resets form and response 3 seconds after magic link is sent
	useEffect(() => {
		if (typeof magicLinkResponse.fulfilledTimeStamp !== 'undefined') {
			setTimeout(() => {
				magicLinkResponse.reset();
				reset();
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [magicLinkResponse.isSuccess]);

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className='space-y-24'>
			<Form.Group>
				<Form.EmailInput
					fieldSize='lg'
					errors={errors?.email}
					startIcon='envelope'
					name='email'
					placeholder={t('common.yourEmail')}
					register={register}
				/>
			</Form.Group>

			<StandardButton
				type='submit'
				disabled={!isValid || magicLinkResponse.isLoading || magicLinkResponse.isSuccess}
				variant='primary'
				size='lg'
				isLoading={magicLinkResponse.isLoading}
				label={t(magicLinkResponse.isSuccess ? 'forms.loginForm.loginLinkSent' : 'forms.loginForm.submitButton')}
				title={t(magicLinkResponse.isSuccess ? 'forms.loginForm.loginLinkSent' : 'forms.loginForm.submitButton')}
				className='w-full'
			/>

			<div
				className={cn(
					variant === 'default' ? 'items-center' : 'items-center md:items-start',
					'flex flex-col justify-center space-y-16 md:space-y-24'
				)}>
				<Text variant='body-s' weight='regular' color='primaryMain'>
					{t('forms.loginForm.orOtherOptions')}
				</Text>

				<div className='flex items-center space-x-24'>
					{supportedSocialLogins.map((socialLogin) => (
						<SocialMediaButton
							key={`social-login-button-${socialLogin}`}
							social={socialLogin}
							onClick={() => onSocialLoginClick(socialLogin)}
						/>
					))}
				</div>
			</div>
		</Form>
	);
};
