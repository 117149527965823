import dynamic from 'next/dynamic';
import { PropsWithChildren, type FC, type ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { ContentGrid, LayoutGrid, Logo } from '@monorepo/components/Misc';
import { useIsMobile } from '@monorepo/shared/lib/hooks';

import { Footer } from '@common/components';
import { selectTheme } from '@common/store';
import { paths } from '@common/utils';

const SvgLogin = dynamic(() => import('@monorepo/shared/components/misc').then((Mod) => Mod.SvgLogin));

type AuthLayoutProps = {
	children: ReactNode;
	showBackground?: boolean;
};

export const OnboardingLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ children, showBackground = true }) => {
	const { isMobile, isSmallTablet } = useIsMobile();
	const theme = useSelector(selectTheme);

	return (
		<>
			<div className='min-h-screen-ios p-0-safe grid min-h-screen grid-rows-[80px_1fr] overflow-x-hidden'>
				<LayoutGrid as='header' className='max-md:border-grayscale100 max-md:bg-grayscale0 py-16 max-md:border-b'>
					<div className='col-start-2 flex items-center max-md:justify-center'>
						<div className='relative h-[32px] w-[200px]'>
							<Logo
								landingHref={paths.root}
								align={isMobile || isSmallTablet ? 'center-contain' : 'left'}
								direction={isMobile || isSmallTablet ? 'center' : 'ltr'}
								theme={theme}
							/>
						</div>
					</div>
				</LayoutGrid>

				<LayoutGrid as='main'>
					<ContentGrid className='col-start-2'>
						<div className='pt-56-safe pb-40-safe md:pt-64-safe col-span-4 flex sm:col-span-6 sm:col-start-2 md:col-span-6 md:col-start-4 md:items-center lg:col-span-8 lg:col-start-9'>
							{children}
						</div>
					</ContentGrid>
				</LayoutGrid>

				<LayoutGrid className='py-32'>
					<div className='col-start-2'>
						<Footer />
					</div>
				</LayoutGrid>
			</div>

			{showBackground && (
				<SvgLogin
					className='fixed -right-[287px] bottom-0 -z-10 h-[640px] w-[562px] select-none object-contain object-bottom md:-right-[312px] md:h-[900px] md:w-[790px]'
					theme={theme}
				/>
			)}
		</>
	);
};
