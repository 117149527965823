import { GetStaticProps } from 'next';
import { composeProps } from 'next-compose-props';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@monorepo/components/TextComponents';
import type { DefaultFunnelPageProps, NextPageWithLayout } from '@monorepo/types';

import { LoginForm } from '@auth/forms';
import { LoginLayout } from '@auth/layouts';
import { Page } from '@common/components';
import { selectThemeContext, wrapper } from '@common/store';
import { getTranslations, paths } from '@common/utils';
import { selectIdTokenState } from '@portal/store';
import { toggleSupportChat } from '@support/store';

export const LoginPage: NextPageWithLayout<DefaultFunnelPageProps<unknown>> = () => {
	const { t } = useTranslation(['common']);
	const { insuranceProvider } = useSelector(selectThemeContext);
	const { push, query } = useRouter();
	const continueUrl = decodeURIComponent(query.continueUrl as string) as `/${string}`;
	const token = useSelector(selectIdTokenState);
	const dispatch = useDispatch();

	useEffect(() => {
		if (token) {
			push(paths.portal.dashboard.root);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<Page title={t('page.auth.login.seo.title')}>
			<div className='w-full space-y-32'>
				<Text variant='display-4' weight='semibold' color='grayscale600'>
					{t('page.auth.login.title', { insuranceProvider })}
				</Text>
				<Text as='p' variant='body-m' color='grayscale500'>
					{t('page.auth.login.subTitle')}
				</Text>

				<LoginForm continueUrl={query.continueUrl ? continueUrl : undefined} />

				<Text as='p' variant='body-s' color='grayscale300' className='md:!mt-64'>
					<Trans
						i18nKey='page.auth.login.support'
						components={{
							support: (
								<span className='text-info300 cursor-pointer underline' onClick={() => dispatch(toggleSupportChat())} />
							),
						}}
					/>
				</Text>
			</div>
		</Page>
	);
};

export const getStaticProps = wrapper.getStaticProps(() => {
	return composeProps(getTranslations) as GetStaticProps;
});

export default LoginPage;

LoginPage.getLayout = (page) => {
	return <LoginLayout>{page}</LoginLayout>;
};
