import dynamic from 'next/dynamic';
import { PropsWithChildren, type FC, type ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { ContentGrid, LayoutGrid, Logo } from '@monorepo/components/Misc';
import { useIsMobile } from '@monorepo/shared/lib/hooks';

import { Footer } from '@common/components';
import { selectTheme } from '@common/store';

const SvgCompanyLogin = dynamic(() => import('@monorepo/shared/components/misc').then((Mod) => Mod.SvgFunnelLogin));

type AuthLayoutProps = {
	children: ReactNode;
};

export const LoginLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({ children }) => {
	const { isMobile, isSmallTablet } = useIsMobile();
	const theme = useSelector(selectTheme);

	return (
		<>
			<div className='min-h-screen-ios p-0-safe grid min-h-screen grid-rows-[80px_1fr] overflow-x-hidden'>
				<LayoutGrid as='header' className='max-md:border-grayscale100 py-16 max-md:border-b'>
					<div className='col-start-2 flex items-center max-md:justify-center'>
						<div className='relative h-[32px] w-[200px]'>
							<Logo
								landingHref='/'
								align={isMobile || isSmallTablet ? 'center' : 'left'}
								direction='ltr'
								theme={theme}
							/>
						</div>
					</div>
				</LayoutGrid>

				<LayoutGrid as='main'>
					<ContentGrid className='col-start-2'>
						<div className='lg:py-124 col-span-full flex flex-col justify-start space-y-24 pb-8 pt-32 sm:pt-48 md:col-span-5 md:justify-center md:space-y-32 lg:col-span-10 lg:col-start-2'>
							{children}
						</div>
						<div className='border-grayscale200 my-124 border-l max-md:hidden md:col-span-1 md:col-start-7 lg:col-start-13' />
						<div className='lg:py-124 lg:col-start-15 col-span-full flex justify-start py-8 max-md:absolute max-md:bottom-0 max-md:right-0 max-md:w-1/2 max-sm:w-1/3 md:col-span-5 md:col-start-8 md:justify-center md:py-32 lg:col-span-9'>
							<SvgCompanyLogin className='h-full w-full' theme={theme} />
						</div>
					</ContentGrid>
				</LayoutGrid>

				<LayoutGrid className='py-32'>
					<div className='col-start-2'>
						<Footer />
					</div>
				</LayoutGrid>
			</div>
		</>
	);
};
