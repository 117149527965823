import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { ToasterNotification } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { emailSchema, EmailSchema } from '@monorepo/shared/lib/schemas';
import { isDevelopment } from '@monorepo/shared/lib/utils';

import { useLogin } from '@auth/hooks';
import { useVerificationLinkMutation } from '@auth/store';
import { selectTheme } from '@common/store';

export const VerifyForm: FC = () => {
	const { t } = useTranslation(['common']);
	const { push } = useRouter();
	const { setLoginEmailForMagicLink } = useLogin();
	const [verifyEmail, verifyEmailResponse] = useVerificationLinkMutation();
	const theme = useSelector(selectTheme);

	const { register, formState, handleSubmit, reset } = useForm<EmailSchema>({
		mode: 'onBlur',
		resolver: zodResolver(emailSchema(t)),
	});

	const onSubmit = async ({ email }: EmailSchema) => {
		await verifyEmail({
			email,
			salesChannel: theme,
			redirectUrl: `${window.location.origin}/inloggen`,
		})
			.unwrap()
			.then(async ({ verificationLink }) => {
				setLoginEmailForMagicLink(email);

				if (isDevelopment && verificationLink) {
					await push(verificationLink);
				}

				toast.custom(
					({ visible }) => (
						<ToasterNotification
							title={t('page.auth.verifyEmail.toastSuccessTitle')}
							visible={visible}
							message={t('page.auth.verifyEmail.toastSuccessMessage')}
							status='success'
						/>
					),
					{
						ariaProps: {
							role: 'alert',
							'aria-live': 'assertive',
						},
					}
				);
			});
	};

	// Resets form and response 3 seconds after magic link is sent
	useEffect(() => {
		if (typeof verifyEmailResponse.fulfilledTimeStamp !== 'undefined') {
			setTimeout(() => {
				verifyEmailResponse.reset();
				reset();
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verifyEmailResponse.isSuccess]);

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className='space-y-32'>
			<Form.Group>
				<Form.EmailInput name='email' placeholder={t('common.yourEmail')} register={register} />
			</Form.Group>

			<StandardButton
				disabled={!formState.isValid || verifyEmailResponse.isLoading || verifyEmailResponse.isSuccess}
				variant='primary'
				size='lg'
				iconRight={verifyEmailResponse.isSuccess ? 'cloud-check' : 'envelope-shield'}
				isLoading={verifyEmailResponse.isLoading}
				label={t(verifyEmailResponse.isSuccess ? 'forms.verifyForm.verifyLinkSent' : 'forms.verifyForm.submitButton')}
				className='w-full'
			/>
		</Form>
	);
};
